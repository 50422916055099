
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【中古車】メルセデスAMGはラインナップが豊富！
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            メルセデスの中古車をお探しの方へ、メルセデスAMGをおすすめします。様々なラインナップがありますので、それぞれの特徴をしっかりと把握し、自分に合ったものを見つけましょう。希少な車種をお探しの方は、car shop MIXにご相談ください。
            <div class="section-title">
              <span >メルセデスAMGのラインナップ！45S搭載モデルも併せてご紹介</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >メルセデスAMGはラインナップが豊富です。中にはモデル体系がわかりにくいと感じる方もいらっしゃるかと思いますが、エンジンを基準に考えるととてもわかりやすいためおすすめです。</p>
                    <p >こちらでは、メルセデスAMGのエンジンバリエーションについてご紹介します。</p>
                    <h3 class="heading-number" data-number="◆">35</h3>  
                    <p >「35」シリーズは、AMGがエントリーレベルとして位置付けるエンジンであり、AクラスやGLAクラスなどのコンパクトなモデルに搭載されています。</p>
                    <p >このエンジンは2.0Lの直列4気筒直噴エンジン（型式：M260）で、ツインスクロールターボチャージャーが装備されています。低回転から迅速なレスポンスと高回転までのスムーズなパワーを実現し、全域で力強い加速を楽しむことができます。</p>
                    <p >また、可変バルブコントロールシステムの採用により、環境性能にも優れています。</p>
                    <h3 class="heading-number" data-number="◆">43</h3>  
                    <p >「43」シリーズの歴史は古く、最初に登場したのは1997年のCクラスでした。当時のC43には、4.3L V型8気筒エンジン（M113）が搭載され、最高出力225kW（306PS）を誇りました。</p>
                    <p >現在の「43」シリーズには、3.0L V型6気筒ツインターボエンジン（M276M30）、3.0L直列6気筒ターボエンジン（M256）、2.0L直列4気筒＋電動ターボエンジン（M139I）の3つのタイプが存在します。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-gtr-amg/lineup-abundance-mercedes-amg.jpg" alt="Image">
                  </div>
              </div>
              <h3 class="heading-number" data-number="◆">45</h3> 
              <p >「45」シリーズは、2019年から導入された2.0L直列4気筒DOHCエンジン（M139）を搭載しており、AクラスやCLAクラスなどのコンパクトクラスのハッチバックやセダン、SUVに装着されています。</p>
              <p >このエンジンには、ベアリングマウントされたツインスクロールターボチャージャーとツインインジェクションの技術が採用されており、世界最高レベルの出力を誇ります。</p> 
              <h3 class="heading-number" data-number="◆">53</h3>
              <p >「53」シリーズは、3.0L直列6気筒ターボエンジンを搭載しており、EクラスやAMG GT 4ドアクーペなどに装着されています。</p>
              <p >このエンジンの魅力は、スムーズな回転フィールを持つ直列6気筒エンジンに、エンジンをサポートするモーター兼発電機のISGが組み合わされていることです。ISGと電動スーパーチャージャーによって、驚くほどの加速性能を実現しています。</p>
              <h3 class="heading-number" data-number="◆">63</h3>
              <p >「63」シリーズは複数の世代が存在し、最初の登場は2014年でCクラスに搭載されました。その後、2016年にはEクラスにも採用されています。現在では、主にGクラスやGLSクラスなどのラグジュアリーモデルやラージサイズモデルに搭載されています。</p>
              <p >最新のユニットは、軽量かつ高強度なクローズドデッキのアルミクランクケース、鍛造アルミ製ピストン、NANOSLIDE加工を施したシリンダーウォールなどの特徴を持ちます。</p>
              <h3 class="heading-number" data-number="◆">「45」シリーズがおすすめ</h3>  
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>メルセデスAMG A45S 4マチック＋</h4>
              <p >メルセデスベンツ「Aクラス」の頂点に位置する高性能モデルです。スポーティーな走行性能と快適な乗り心地を兼ね備えています。</p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>メルセデスAMG CLA45S 4マチック＋</h4>
              <p >流れるような美しいフォルムが特徴的で、走行性能と環境性能を両立させたモデルです。可変バルブコントロールシステムを採用することで、排ガスを抑えた走行を可能としています。</p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>メルセデスAMG GLA45S 4マチック＋</h4>
              <p >フロントにはA45と同じくAMG専用ラジエターグリルを採用しています。また左右の外側エアインテークを横方向に走るフィンと、幅を広げた中央下側のエアインテークによりワイドな印象を強調しています。</p>
              <div class="c-btn">
                <router-link to="/jigyo/oldCars" class="c-btn-anchor">中古車</router-link>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >国産車・外車問わず、中古車探しはcar shop MIXにお任せ</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >AMGモデルは、レーシングテクノロジーと最先端技術を駆使して製造されています。</p>
                    <p >メルセデスベンツの高級感とAMGの快適性と走りを両立したハイパフォーマンスが特徴的です。高級感のあるデザインを残しつつも、スポーティーなフォルムやエクステリアデザインが多くのファンを魅了しています。</p>
                    <p >メルセデスAMGのラインナップやS45搭載車種をご紹介しましたが、中古車を選ぶ際は、求める性能やデザインなどをしっかりとイメージすることが大切です。</p>
                    <p >car shop MIXは、一般的に市場に出回らないような車種やモデルを多く取り扱っております。低走車や25年規制が解除されるような車を中心に、幅広いモデルをお探しすることが可能です。</p>
                    <p >AMG 45SやAMG 4マチック＋などの人気モデルもご用意可能ですので、レアな車をお探しでしたらぜひお気軽にお問い合わせください。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="メルセデスAMGのロゴマーク" src="@/assets/img/column-gtr-amg/mercedes-amg-logo-mark.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/contact" class="c-btn-anchor">お問い合わせ</router-link>
              </div>
            </div>
          </div>
          <column-footer :title="'AMG 45SやAMG 4マチック＋などの人気モデルの中古車はcar shop MIXへご相談ください！'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-gtr-amg/head-mv08.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}


</style>
